<finstein-base-modal [disableClose]="false" bodyClass="none">
    <finstein-circle-info header></finstein-circle-info>
    <div content>
        <p class="mt-2" translate>approvals-requests.reject-modal.title</p>
        <textarea [placeholder]="'approvals-requests.reject-modal.insert-comment' | translate"
                  [(ngModel)]="message"></textarea>
    </div>
    <div actions>
        <button type="button" class="btn btn-primary mr-1" (click)="submit()">
            {{ 'system.send' | translate }}
        </button>
    </div>
</finstein-base-modal>
