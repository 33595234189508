import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeechBubbleTabComponent } from './speech-bubble-tab.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [SpeechBubbleTabComponent],
    exports: [SpeechBubbleTabComponent],
})
export class SpeechBubbleTabModule {}
