import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-speech-bubble-tab',
    templateUrl: './speech-bubble-tab.component.html',
    styleUrls: ['./speech-bubble-tab.component.scss']
})
export class SpeechBubbleTabComponent {

    @Input()
    enableFadeIn = true;

    @Input()
    enableShakeUp = true;

}
