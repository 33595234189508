/* src/app/core/components/rejection-message-modal/rejection-message-modal.component.scss */
textarea {
  width: 100%;
  height: 130px;
  padding: 18px;
  border: 1px solid #d7d6d6;
  border-radius: 6px;
  outline-color: #719ECE;
}
textarea:focus {
  outline-color: #719ECE;
}
/*# sourceMappingURL=rejection-message-modal.component-TL4VXLAQ.css.map */
