/* src/app/shared/components/expandable-card-ribbon/expandable-card-ribbon.component.scss */
.bg-pendent-changes {
  background: #f5f5f5;
}
.btn-approve-pendent-changes {
  color: #28a745;
}
.btn-approve-pendent-changes i {
  color: #28a745;
}
.btn-reject-pendent-changes {
  color: #dc3545;
}
.btn-reject-pendent-changes i {
  color: #dc3545;
}
.btn-action {
  background-color: #0760ef;
}
.page-description,
.page-description-opened {
  font-size: 13px;
  font-weight: 600;
  color: #394551;
  padding-left: 70px;
  letter-spacing: 0.5px;
}
.page-description {
  line-height: 1.8;
}
.page-description-opened {
  color: white;
  line-height: 34px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  white-space: nowrap;
}
ngb-accordion ::ng-deep .card {
  border: none;
}
ngb-accordion ::ng-deep .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: none;
}
.header-toggle {
  outline: none;
  border: 0;
}
.header-collapsed,
.header-opened {
  width: 450px;
  padding-top: 26px;
  padding-bottom: 26px;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  background: white;
  border: none;
  box-shadow: 0 5px 8px 0 rgba(168, 176, 219, 0.1607843137);
}
.header-opened {
  width: 100%;
  margin-bottom: 0 !important;
  padding: 25px;
  box-shadow: 0 -5px 8px 0 rgba(168, 176, 219, 0.1607843137);
}
ngb-accordion ::ng-deep .card-body {
  margin-bottom: 40px;
  padding: 25px;
  box-shadow: 0 8px 8px 0 rgba(168, 176, 219, 0.1607843137);
}
.ribbon-wrapper {
  position: absolute;
  z-index: 1;
  outline: 0;
  border: none;
  padding: 0;
  left: -14px;
}
.ribbon {
  height: 34px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  clip-path: polygon(17% 0, 25% 50%, 17% 100%, 0 100%, 0 0);
  width: 200px;
}
.opened {
  width: auto;
  clip-path: polygon(calc(100% - 25px) 0%, 100% 50%, calc(100% - 25px) 100%, 0 100%, 0 0);
}
.ribbon-wrapper::before {
  width: 15px;
  height: 20px;
  top: 24.5px;
  left: 0;
  background-color: #414E6C;
  content: "";
  display: block;
  position: absolute;
  clip-path: polygon(0 46%, 100% 100%, 100% 44%);
}
.color-finstein {
  background-color: #0760ef;
}
.ribbon.color-finstein::after {
  border-left: 17px solid #0760ef;
}
.color-light-blue-4 {
  background-color: #70EBDD;
}
.ribbon.color-light-blue-4::after {
  border-left: 17px solid #70EBDD;
}
.color-light-blue-5 {
  background-color: #41BCD9;
}
.ribbon.color-light-blue-5::after {
  border-left: 17px solid #41BCD9;
}
.color-cornflower-blue-1 {
  background-color: #697FDE;
}
.ribbon.color-cornflower-blue-1::after {
  border-left: 17px solid #697FDE;
}
.color-cornflower-blue-2 {
  background-color: #72A3FF;
}
.ribbon.color-cornflower-blue-2::after {
  border-left: 17px solid #72A3FF;
}
.color-mauvelous {
  background-color: #FC94A4;
}
.ribbon.color-mauvelous::after {
  border-left: 17px solid #FC94A4;
}
.color-hit-pink {
  background-color: #FFA872;
}
.ribbon.color-hit-pink::after {
  border-left: 17px solid #FFA872;
}
.color-m-slate-blue {
  background-color: #7667EB;
}
.ribbon.color-m-slate-blue::after {
  border-left: 17px solid #7667EB;
}
.color-ocean-blue {
  background-color: #41BCD9;
}
.ribbon.color-ocean-blue::after {
  border-left: 17px solid #41BCD9;
}
.white {
  color: white;
}
.color-havelock-blue {
  background-color: #58ABE0;
}
.ribbon.color-havelock-blue::after {
  border-left: 17px solid #58ABE0;
}
.color-pale-violet-red {
  background-color: #DE697B;
}
.ribbon.color-pale-violet-red::after {
  border-left: 17px solid #DE697B;
}
.color-medium-turquoise {
  background-color: #41BCD9;
}
.ribbon.color-medium-turquoise::after {
  border-left: 17px solid #41BCD9;
}
.color-sandy-brown {
  background-color: #FFC048;
}
.ribbon.color-sandy-brown::after {
  border-left: 17px solid #FFC048;
}
.color-lights-late-gray {
  background-color: #7F7EA3;
}
.ribbon.color-lights-late-gray::after {
  border-left: 17px solid #7F7EA3;
}
.color-edit-icon-blue-1 {
  color: #697FDE;
}
.notification-icon {
  position: absolute;
  right: -10%;
  top: -10%;
}
.btn-actions {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 99;
}
.btn-actions .btn-cancel {
  color: #dc3545;
}
.btn-actions .btn-cancel i {
  color: #dc3545;
}
.scale-in-center {
  -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  14.28% {
    transform: translate(0, 5px);
  }
  28.56% {
    transform: translate(0, 0);
  }
  42.84% {
    transform: translate(0, 5px);
  }
  57.12% {
    transform: translate(0, 0);
  }
  71.40% {
    transform: translate(0, 5px);
  }
  85.68% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
::ng-deep ngb-accordion .content {
  position: relative;
}
::ng-deep ngb-accordion .card-body {
  width: 100%;
}
.color-success {
  background-color: #28a745;
}
.ribbon.color-success::after {
  border-left: 17px solid #28a745;
}
.red-color {
  color: red;
}
.schedule-icon {
  position: absolute;
  right: 10px;
  top: 1px;
  color: #6c6c6c;
}
/*# sourceMappingURL=expandable-card-ribbon.component-VIOET4V4.css.map */
