import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { BaseComponent } from '../../../core/abstractions/base.component';
import { STATUS_APPROVED, STATUS_REJECTED } from '../../../app.constants';
import { RejectionMessageModalComponent } from '../../../core/components/rejection-message-modal/rejection-message-modal.component';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

declare let $: any;

@Component({
    selector: 'app-expandable-card-ribbon',
    templateUrl: './expandable-card-ribbon.component.html',
    styleUrls: [
        './expandable-card-ribbon.component.scss',
        '../historical-data/components/historical-data-view/historical-data-view.component.scss'
    ],
})
export class ExpandableCardRibbonComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    private _submitButtonDisabled = false;
    private titleValue: string;
    _editMode = false;

    showNotificationEditIcon = false;
    showNotificationHistoricalIcon = false;

    private toggleEventSubscription: Subscription;

    @Input() tooltipText: string;

    @ViewChild('acc')
    accordionComponent: NgbAccordion;

    @Input() showIconsApproveChanges = false;
    @Input() pendentApproval: any;
    @Input() openOnInit: boolean;
    @Input() alwaysOpened: boolean;
    @Input() showEditButton = true;
    @Input() showSaveButton = false;

    @Input() notificationIconTooltip: string;

    @Input() color: string;
    @Input() tooltipPosition: 'top'
        | 'left top'
        | 'top'
        | 'right left '
        | undefined
        | 'right bottom'
        | 'left bottom '
        | 'bottom'
        | 'right' = 'right';

    @Input() editForm: boolean;
    @Input() enableTitleInUppercase = true;
    @Input() toggleEvent: Observable<any> = new Observable<any>();
    @Input() currentForm: FormGroup;
    @Output() toggleEditBtn: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() approveOrReject: EventEmitter<any> = new EventEmitter();

    @Input() accordionId: string;
    @Input() enableScroll: boolean;

    @Input()
    public set submitButtonDisabled(value: boolean) {
        this._submitButtonDisabled = value;
        this.enableSaveButton();
    }

    public get submitButtonDisabled() {
        return this._submitButtonDisabled;
    }

    public get editMode() {
        if (this.currentForm) {
            return !this.currentForm.disabled;
        } else {
            return this._editMode;
        }
    }

    constructor(private elementRef: ElementRef,
                private dialog: MatDialog,
                private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        if (!this.accordionId) {
            this.accordionId = uuidv4();
        }
        if (!this.showNotificationIcon) {
            if (this.alwaysOpened || this.activatedRoute.snapshot.fragment === this.accordionId) {
                this.openOnInit = true;
            }
            if (this.openOnInit) {
                // The SetTimeout function is used to not throw the ExpressionChangedAfterItHasBeenCheckedError error;
                setTimeout(() => this.accordionComponent.toggle(this.accordionId));
            }
        } else if (this.activatedRoute.snapshot.fragment === this.accordionId) {
            setTimeout(() => this.accordionComponent.toggle(this.accordionId));
        }
        this.toggleEventSubscription = this.toggleEvent.subscribe(
            (toggleEditMode) => {
                this._editMode = toggleEditMode;
            }
        );
    }

    ngOnDestroy(): void {
        this.toggleEventSubscription.unsubscribe();
    }

    toggle() {
        if (!this.alwaysOpened) {
            this.accordionComponent.toggle(this.accordionId);
        }
    }

    open() {
        if (this.accordionComponent) {
            this.accordionComponent.expand(this.accordionId);
        }
    }

    @Input()
    set title(value: string) {
        this.titleValue = value;
        this.elementRef.nativeElement.title = '';
    }

    get title() {
        return this.translateService.instant(this.titleValue);
    }

    enableSaveButton() {
        setTimeout(() => {
            const element = $('#saveDisabledButton');
            if (this.submitButtonDisabled) {
                element.unbind('click');
            } else {
                element.bind('click');
            }
        }, 100);
    }

    editEvent() {
        this._editMode = true;
        this.toggleEditBtn.emit();
    }

    closeEvent() {
        this._editMode = false;
        this.toggleEditBtn.emit();
    }

    disableButton() {
        return false;
    }

    onSubmit() {
        if (!this.submitButtonDisabled) {
            // this.editMode = false;
            this.save.emit();
        }

    }

    @Input()
    set showNotificationIcon(value: boolean) {
        this.showNotificationEditIcon = value;
        if (value) {
            this.open();
        }
    }

    @Input()
    set showHistoricalChange(value: boolean) {
        this.showNotificationHistoricalIcon = value;
        if (value) {
            this.open();
            this.showNotificationEditIcon = false;
        }
    }

    approve() {
        this.approveOrReject.emit({feedback: STATUS_APPROVED, pendentApproval: this.pendentApproval});
    }

    reject() {
        this.dialog.open(RejectionMessageModalComponent, {
            panelClass: 'curved-modal',
            width: '40vw',
        })
            .afterClosed()
            .subscribe((result: any) => {
                if (result.submit) {
                    this.approveOrReject.emit({feedback: STATUS_REJECTED, pendentApproval: this.pendentApproval, message: result.message});
                }
            });
    }

    saveButtonEnabled() {
        return !this.currentForm.pristine;
    }

}
