/* src/app/shared/components/speech-bubble-tab/speech-bubble-tab.component.scss */
.bubble-info-task-to-do {
  display: flex;
  align-items: center;
  height: 32px;
  width: auto;
  background: white;
  border: 1px solid red;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  padding-left: 10px;
  padding-right: 10px;
}
.bubble-info-task-to-do span {
  text-transform: uppercase;
  font-size: 10px;
  color: red;
  letter-spacing: 0;
  font-weight: normal;
}
.bubble-info-task-to-do i {
  font-size: 20px;
  color: red;
  font-weight: normal;
  padding-left: 2px;
  margin-left: 12px;
}
.bubble-info-task-to-do:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: red;
  border-bottom: 0;
  margin-left: -17px;
  margin-bottom: -5px;
}
.shake-up {
  animation: shake-animation 6s ease infinite;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}
.fade-in {
  visibility: hidden;
  animation: 2s fadeIn;
  visibility: hidden;
  animation-fill-mode: forwards;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(0, 5px);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(0, 5px);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(0, 5px);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes fadeIn {
  50% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
/*# sourceMappingURL=speech-bubble-tab.component-L6DJJHHS.css.map */
