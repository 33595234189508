import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { RejectionMessageModalComponent } from './rejection-message-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        BaseModalModule,
        FormsModule
    ],
    declarations: [RejectionMessageModalComponent],
    exports: [RejectionMessageModalComponent]
})
export class RejectionMessageModalModule {
}
