import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FinsteinTooltipModule } from 'src/app/core/directives/finstein-tooltip/finstein-tooltip.module';
import { SpeechBubbleTabModule } from '../speech-bubble-tab/speech-bubble-tab.module';
import { ExpandableCardRibbonComponent } from './expandable-card-ribbon.component';
import { RejectionMessageModalModule } from '../../../core/components/rejection-message-modal/rejection-message-modal.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbAccordionModule,
        FinsteinTooltipModule,
        FinsteinTooltipModule,
        SpeechBubbleTabModule,
        RejectionMessageModalModule
    ],
    declarations: [ExpandableCardRibbonComponent],
    exports: [ExpandableCardRibbonComponent],
})
export class ExpandableCardRibbonModule {
}
