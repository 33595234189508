<ngb-accordion #acc="ngbAccordion">
    <ngb-panel [id]="accordionId">

        <ng-template ngbPanelHeader let-opened="opened">
            <button class="header-toggle remove-default-tooltip relative"  data-test="expandable-card-toggle-button"
                    [ngClass]="opened ? 'header-opened' : 'header-collapsed'" (click)="toggle()" type="button">
                <div class="ribbon-wrapper">
                    <div #tooltipDiv>
                        <div class="{{color}}" [ngClass]="['ribbon', opened ? 'opened' : '' ]">
                            <div class="d-flex align-items-center" *ngIf="opened">
                                <div class="page-description-opened d-flex align-items-center">
                                    <span class="pr-4"
                                          [ngStyle]="{'text-transform': enableTitleInUppercase ? ' uppercase' : ''}"> {{ title }}</span>
                                    <span class="material-icons white pr-2"
                                          *ngIf="tooltipText"
                                          [finsteinTooltip]="tooltipText"
                                          [tooltipPos]="tooltipPosition"
                                          [showTooltipIn]="tooltipDiv"
                                          [tooltipAutoSize]="true"
                                          [enableTooltip]="opened">
                                        info
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative">
                    <span *ngIf="!opened" class="page-description"
                          [ngStyle]="{'text-transform': enableTitleInUppercase ? ' uppercase' : ''}">{{ title }}</span>
                    <i *ngIf="!opened && showNotificationHistoricalIcon"
                       class="material-icons cursor-pointer schedule-icon pulse-icon">
                        schedule
                    </i>
                </div>
                <app-speech-bubble-tab *ngIf="!opened && showNotificationEditIcon"
                                       class="notification-icon"></app-speech-bubble-tab>
            </button>
            <div class="btn-actions" *ngIf="opened">
                <button data-test="expandable-card-toggle-approval-button" *ngIf="pendentApproval && showIconsApproveChanges"
                        class="btn ml-auto btn-link offset-12 btn-approve-pendent-changes"
                        type="button"
                        (click)="approve()"
                        tooltipPos="up-right"
                        [showTooltipIn]="checkPendentIcon"
                        finsteinTooltip="tooltips.approve-pendent-changes">
                    <i class="material-icons icon" #checkPendentIcon>check</i>
                </button>

                <button data-test="expandable-card-reject-button" *ngIf="pendentApproval && showIconsApproveChanges"
                        class="btn ml-auto btn-link offset-12 btn-reject-pendent-changes"
                        type="button"
                        (click)="reject()"
                        tooltipPos="up-right"
                        [showTooltipIn]="closePendentIcon"
                        finsteinTooltip="tooltips.reject-pendent-changes">
                    <i class="material-icons icon" #closePendentIcon>close</i>
                </button>

                <button data-test="expandable-card-edit-button" *ngIf="editForm && showEditButton && !showSaveButton && !editMode && (!pendentApproval || !showIconsApproveChanges)"
                        class="btn ml-auto btn-link offset-12"
                        type="button" (click)="editEvent()"
                        tooltipPos="up-right"
                        [showTooltipIn]="editIcon"
                        finsteinTooltip="tooltips.edit">
                    <i [ngClass]="showNotificationEditIcon ? 'red-color' : ''"
                       class="material-icons icon" #editIcon>
                        create
                    </i>
                </button>
                <div class="mr-4" *ngIf="editForm && showEditButton && !showSaveButton && editMode && (!pendentApproval || !showIconsApproveChanges)">
                    <button data-test="expandable-card-cancel-button" type="button"
                            class="btn ml-auto btn-link offset-12 btn-cancel"
                            style="color: red"
                            tooltipPos="up-right"
                            [showTooltipIn]="closeIcon"
                            finsteinTooltip="tooltips.cancel"
                            (click)="closeEvent()">
                        <i class="material-icons icon" #closeIcon>close</i>
                    </button>
                    <button data-test="expandable-card-save-button" [disabled]="submitButtonDisabled"
                            type="button"
                            class="btn ml-auto btn-link offset-12"
                            (click)="onSubmit()"
                            tooltipPos="up-right"
                            [showTooltipIn]="saveIcon"
                            finsteinTooltip="tooltips.save">
                        <i class="material-icons icon" #saveIcon>
                            save
                        </i>
                    </button>
                </div>
                <div class="mr-4" *ngIf="showSaveButton && !showEditButton && editMode && (!pendentApproval || !showIconsApproveChanges)">
                    <button [disabled]="submitButtonDisabled"
                            *ngIf="saveButtonEnabled()"
                            type="button"
                            class="btn btn-outline-primary rounded"
                            [ngClass]="{'scale-in-center': saveButtonEnabled()}"
                            (click)="onSubmit()"
                            translate>
                            system.save
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" [ngStyle]="{ 'overflow': enableScroll ? 'scroll' : 'none'}">
                <div class="col-12">
                    <ng-content></ng-content>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
