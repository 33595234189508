import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './rejection-message-modal.component.html',
    styleUrls: ['./rejection-message-modal.component.scss']
})
export class RejectionMessageModalComponent {

    message = '';

    constructor(private dialogRef: MatDialogRef<RejectionMessageModalComponent>) {
    }

    submit() {
        this.dialogRef.close({submit: true, message: this.message});
    }

}
